import React, { FunctionComponent } from "react";
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import style from "../components/page/page.module.css"
import PageBlocks from "../components/PageBlocks";

interface IHomepageProps {
  data: GatsbyTypes.HomepageQuery;
}

const Homepage: FunctionComponent<IHomepageProps> = ({ data }) => {
  const page = data.page
  const description = page.openGraph.description || ""
  const openGraphImage = (page.openGraph.image.asset && page.openGraph.image.asset.fixed.src)
  return(
    <Layout>
      <SEO image={openGraphImage} description={description} slug={data.page.slug.current} />
      <div className={style.main}>
        <PageBlocks page={page} />
      </div>
    </Layout>
  );
}

export default Homepage

export const pageQuery = graphql`
  query Homepage {
    page: sanityPage(slug: {current: {eq: "home"}}) {
      slug {
        current
      }
      _id
      title
      openGraph {
        title
        description
        image {
          asset {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
      _rawContent(resolveReferences: {maxDepth: 5})
    }
  }
`